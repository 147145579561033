.movfooter {
  height: 5rem;
  background: rgb(255, 255, 255);
  display: none;
  @include responsive-for(md) {
    display: block;
  }
}
section[role='Footer'] {
  margin-top: 8rem;
  background: color(primary, base);
  @include responsive-for(md) {
    display: none;
  }

  .foooterInfo {
    margin-top: 3rem;
    img {
      width: 12rem;
    }
    p {
      color: white;
      line-height: 180%;
      width: 100%;
      margin-top: 2rem;
    }
  }

  //links

  .Links {
    color: white;
    &-title {
      color: white;
      margin-top: 3rem;
      @include responsive-for(sm) {
        margin-top: 2rem;
      }
    }

    &-box {
      margin-top: 2rem;
      padding: 0;
      &-item {
        margin-top: 1rem;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  //contact info

  .contact {
    color: white;
    &-title {
      margin-top: 3rem;
      @include responsive-for(sm) {
        margin-top: 2rem;
      }
    }

    &-box {
      padding: 0;
      &-item {
        margin-top: 2rem;
        display: flex;

        .iconHolder {
          margin-right: 1rem;
        }
        .addHolder {
          &-key {
          }

          &-value {
            font-size: 14px;
            font-weight: 100;
            color: white !important;
          }
        }
      }
    }
  }

  .socialMedia {
    color: white;
    &-title {
      margin-top: 3rem;
      @include responsive-for(sm) {
        margin-top: 2rem;
      }
    }

    .socialWrapper {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin-top: 2rem;

      &-icons {
        cursor: pointer;
        margin-right: 1rem;
        border: 1px solid white;
        height: 2.5rem;
        width: 2.5rem;
        border-radius: calc(2.5rem);
        @include flex-center();
        transition: all ease-in 0.2s;

        i {
          color: color(gray, four);
        }

        &:hover {
          background-color: white;
          color: color(secondary, base);
        }
      }
    }
  }
}

.copyright {
  padding-bottom: 3rem;
  color: white;
  text-align: center;
}
