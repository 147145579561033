.accountWrapper {
  margin-top: 2rem;

  .accountTabHolder {
    &-items {
      padding: 0.5rem;
      color: color(text-dark);
      cursor: pointer;
      &:hover {
        color: color(primary, base);
      }
    }
  }
}

.orderWrapper {
  box-shadow: $shadow;
  padding: 0.5rem;
  margin-top: 2rem;
  thead {
    background: white;
    color: color(text-dark);
  }
  th {
    font-weight: 600 !important;
    border-width: 0px !important;
  }
  .imageHolder {
    display: flex;
    align-items: center;

    figure {
      margin: 0;
      img {
        height: 2.5rem;
        width: 2.5rem;
        object-fit: contain;
        margin-right: 1rem;
        border: $border;
      }
    }

    &-text {
      font-size: 1.1rem;
      @include responsive-for(sm) {
        font-size: 14px;
      }
    }
  }
}

.detailsWrapper {
  width: 50%;
  @include responsive-for(md) {
    width: 100%;
  }
}

.guestRegister {
  @include responsive-for(sm) {
    display: none;
  }
}
