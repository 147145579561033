.image-gallery-icon {
  color: #fff;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  -webkit-filter: drop-shadow(0 2px 2px #1a1a1a);
          filter: drop-shadow(0 2px 2px #1a1a1a);
}

@media (hover: hover) and (pointer: fine) {
  .image-gallery-icon:hover {
    color: #337ab7;
  }
  .image-gallery-icon:hover .image-gallery-svg {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}

.image-gallery-icon:focus {
  outline: 2px solid #337ab7;
}

.image-gallery-using-mouse .image-gallery-icon:focus {
  outline: none;
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
  padding: 20px;
}

.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  height: 28px;
  width: 28px;
}

@media (max-width: 768px) {
  .image-gallery-fullscreen-button,
  .image-gallery-play-button {
    padding: 15px;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 24px;
    width: 24px;
  }
}

@media (max-width: 480px) {
  .image-gallery-fullscreen-button,
  .image-gallery-play-button {
    padding: 10px;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 16px;
    width: 16px;
  }
}

.image-gallery-fullscreen-button {
  right: 0;
}

.image-gallery-play-button {
  left: 0;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 120px;
  width: 60px;
}

@media (max-width: 768px) {
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 72px;
    width: 36px;
  }
}

@media (max-width: 480px) {
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 48px;
    width: 24px;
  }
}

.image-gallery-left-nav[disabled],
.image-gallery-right-nav[disabled] {
  cursor: disabled;
  opacity: .6;
  pointer-events: none;
}

.image-gallery-left-nav {
  left: 0;
}

.image-gallery-right-nav {
  right: 0;
}

.image-gallery {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
}

.image-gallery.fullscreen-modal {
  background: #000;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 5;
}

.image-gallery.fullscreen-modal .image-gallery-content {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0;
}

.image-gallery-content.fullscreen {
  background: #000;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 80px);
}

.image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image {
  max-height: 100vh;
}

.image-gallery-slide-wrapper {
  position: relative;
}

.image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
  display: inline-block;
  width: calc(100% - 110px);
}

@media (max-width: 768px) {
  .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
    width: calc(100% - 87px);
  }
}

.image-gallery-slide-wrapper.image-gallery-rtl {
  direction: rtl;
}

.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-align: center;
}

.image-gallery-slide {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.image-gallery-slide.center {
  position: relative;
}

.image-gallery-slide .image-gallery-image {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.image-gallery-slide .image-gallery-description {
  background: rgba(0, 0, 0, 0.4);
  bottom: 70px;
  color: #fff;
  left: 0;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  white-space: normal;
}

@media (max-width: 768px) {
  .image-gallery-slide .image-gallery-description {
    bottom: 45px;
    font-size: .8em;
    padding: 8px 15px;
  }
}

.image-gallery-bullets {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 80%;
  z-index: 4;
}

.image-gallery-bullets .image-gallery-bullets-container {
  margin: 0;
  padding: 0;
  text-align: center;
}

.image-gallery-bullets .image-gallery-bullet {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 2px #1a1a1a;
          box-shadow: 0 2px 2px #1a1a1a;
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
  outline: none;
  padding: 5px;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
}

@media (max-width: 768px) {
  .image-gallery-bullets .image-gallery-bullet {
    margin: 0 3px;
    padding: 3px;
  }
}

@media (max-width: 480px) {
  .image-gallery-bullets .image-gallery-bullet {
    padding: 2.7px;
  }
}

.image-gallery-bullets .image-gallery-bullet:focus {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  background: #337ab7;
  border: 1px solid #337ab7;
}

.image-gallery-bullets .image-gallery-bullet.active {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  border: 1px solid #fff;
  background: #fff;
}

@media (hover: hover) and (pointer: fine) {
  .image-gallery-bullets .image-gallery-bullet:hover {
    background: #337ab7;
    border: 1px solid #337ab7;
  }
  .image-gallery-bullets .image-gallery-bullet.active:hover {
    background: #337ab7;
  }
}

.image-gallery-thumbnails-wrapper {
  position: relative;
}

.image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
  -ms-touch-action: pan-y;
      touch-action: pan-y;
}

.image-gallery-thumbnails-wrapper.thumbnails-swipe-vertical {
  -ms-touch-action: pan-x;
      touch-action: pan-x;
}

.image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
  direction: rtl;
}

.image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
  display: inline-block;
  vertical-align: top;
  width: 100px;
}

@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    width: 81px;
  }
}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
  height: 100%;
  width: 100%;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
  display: block;
  margin-right: 0;
  padding: 0;
}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 0;
  margin-top: 2px;
}

.image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
  margin: 0 5px;
}

@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    margin: 0 3px;
  }
}

.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0;
}

@media (max-width: 768px) {
  .image-gallery-thumbnails {
    padding: 3px 0;
  }
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
}

.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  -webkit-transition: border .3s ease-out;
  transition: border .3s ease-out;
  width: 100px;
  background: transparent;
  padding: 0;
}

@media (max-width: 768px) {
  .image-gallery-thumbnail {
    border: 3px solid transparent;
    width: 81px;
  }
}

.image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 2px;
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  display: block;
  position: relative;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  vertical-align: middle;
  width: 100%;
  line-height: 0;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
  outline: none;
  border: 4px solid #337ab7;
}

@media (max-width: 768px) {
  .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
    border: 3px solid #337ab7;
  }
}

@media (hover: hover) and (pointer: fine) {
  .image-gallery-thumbnail:hover {
    outline: none;
    border: 4px solid #337ab7;
  }
}

@media (hover: hover) and (pointer: fine) and (max-width: 768px) {
  .image-gallery-thumbnail:hover {
    border: 3px solid #337ab7;
  }
}

.image-gallery-thumbnail-label {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: white;
  font-size: 1em;
  left: 0;
  line-height: 1em;
  padding: 5%;
  position: absolute;
  top: 50%;
  text-shadow: 0 2px 2px #1a1a1a;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  white-space: normal;
  width: 100%;
}

@media (max-width: 768px) {
  .image-gallery-thumbnail-label {
    font-size: .8em;
    line-height: .8em;
  }
}

.image-gallery-index {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
}

@media (max-width: 768px) {
  .image-gallery-index {
    font-size: .8em;
    padding: 5px 10px;
  }
}

/* 2.3 Font weight*/
/* 2.1 Font Size*/
/* 3.0 Heading Size*/
@-webkit-keyframes preloadAnim {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
@keyframes preloadAnim {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}

.text-bold {
  font-weight: bold;
}

.h1 {
  font-size: 28px;
}

.h2 {
  font-size: 24px;
}

.color-primary {
  color: #0890b9;
}

.color-gray1 {
  color: #b3b3b3;
}

.color-gray2 {
  color: #cccccc;
}

.color-gray3 {
  color: #e6e6e6;
}

.color-gray4 {
  color: #f4f4f4;
}

.pagination {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.page-link {
  color: #0890b9 !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #0890b9 !important;
  border-color: #0890b9 !important;
}

.modalBody {
  padding: 1rem;
  text-align: center;
  font-size: 24px;
}

.modalBody-title {
  text-align: center;
  margin-bottom: 1rem;
}

.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: 0.25rem;
  color: #383838;
  width: 12rem !important;
  text-align: center;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #0890b9 !important;
  background: white !important;
  font-weight: 600;
  border-bottom: 2px solid;
  width: 9rem;
  border-radius: 0;
  text-align: center;
}

.nav-link:hover {
  color: #383838 !important;
  cursor: pointer;
}

.cpntr {
  cursor: pointer;
}

svg:not(:root) {
  overflow: unset;
}

html {
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
  overflow-y: scroll;
}

div {
  font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
  font-weight: 400;
  background: #f8f8f8;
  font-family: 'Montserrat', sans-serif;
}

h1,
.h1,
h2,
.h2 {
  margin-top: 0;
  margin-bottom: 0.1rem;
  color: #1d2a35;
  line-height: 150%;
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-size: 28px !important;
  font-weight: 500 !important;
}

h2 {
  font-size: 24px !important;
  color: #1d2a35;
}

h3 {
  font-size: 18px !important;
  font-weight: 300 !important;
}

p {
  margin-top: 0;
  line-height: 170%;
  font-size: 400;
}

button:focus,
textarea:focus,
input:focus {
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

a {
  text-decoration: none !important;
  color: unset;
}

a:hover {
  color: #354c74;
}

ul {
  list-style: none;
  padding: 0;
}

.clearfix:before, .clearfix:after {
  display: table;
  content: '';
}

.clearfix:after {
  clear: both;
}

.form-check-input {
  cursor: pointer;
}

thead {
  background: #3cc13b;
  color: white;
}

th {
  font-weight: 500;
  font-size: 18px;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
}

td {
  border: none !important;
  padding: 0.7rem !important;
  font-size: 14px;
  color: #1d2a35;
}

thead {
  border-style: hidden !important;
}

.strongUp {
  text-transform: uppercase;
  color: #354c74;
}

.cpr {
  color: #0890b9;
}

.cprt {
  color: #253f74;
}

.bpr {
  background: #0890b9;
}

.bprt {
  background: #253f74;
}

.cse {
  color: #354c74;
}

.bse {
  background: #354c74;
}

.csu {
  color: #3cc13b;
}

.bsu {
  background: #3cc13b;
}

.csut {
  color: #d7f3d7;
}

.bsut {
  background-color: #d7f3d7;
}

.cwa {
  color: #f3bb1c;
}

.bwa {
  background: #f3bb1c;
}

.cwat {
  color: #fdf1d1;
}

.bwat {
  background: #fdf1d1;
}

.cda {
  color: #f03738;
}

.bda {
  background: #f03738;
}

.bdat {
  background: #fcd7d7;
}

.bcdat {
  background: #fcd7d7;
}

.flex-between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.bton {
  font-weight: 400;
  letter-spacing: 0.5px;
  border-radius: 4px;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  font-size: 16px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.bton--sm {
  padding-left: 20px;
  padding-right: 20px;
  line-height: 34px;
}

.bton--md {
  padding-left: 28px;
  padding-right: 28px;
  line-height: 39px;
}

.bton--lg {
  padding-left: 36px;
  padding-right: 36px;
  line-height: 39px;
}

.bton--full {
  line-height: 48px;
  width: 100%;
}

.bton--half {
  line-height: 48px;
  width: 50%;
}

.bton--primary {
  border-color: #0890b9;
  color: white;
  background-color: #0890b9;
}

.bton--primary:hover {
  background-color: #0890b9;
  border-color: #0890b9;
  color: white;
}

.bton--sec {
  border-color: #354c74;
  color: white;
  background-color: #354c74;
}

.bton--sec:hover {
  background-color: #0890b9;
  border-color: #0890b9;
  color: white;
}

.bton--ghost {
  border-radius: 4px;
  border-color: #0890b9;
  color: #0890b9;
  background-color: #ffffff;
}

.bton--ghost:hover {
  background-color: #0890b9;
  border-color: #0890b9;
  color: white;
}

.bton--ghost--danger {
  border-color: #f03738;
  background: #fcd7d7;
  color: #f03738;
}

.bton--ghost--danger:hover {
  background-color: #f03738;
  border-color: #f03738;
  color: white;
}

.bton--secondary {
  border-color: white;
  color: white;
  background-color: transparent;
}

.bton--secondary:hover {
  background: white;
  color: #354c74;
}

.bton--google {
  border-color: white;
  color: white;
  background-color: #3f7ee8;
}

.bton--facebook {
  border-color: white;
  color: white;
  background-color: #385490;
}

.bton--danger {
  background: #f03738;
  color: white;
  border: #f03738;
}

.bton--nacked {
  border: none;
  color: #1414b9;
  background: none;
  padding: 0;
}

.searchComp-input {
  height: 42px;
  margin-right: 5px;
  width: 25rem;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 1rem;
}

.dateComp .dateInput {
  height: 42px;
  margin-right: 5px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 1rem;
  color: #383838;
}

.checkBox {
  border-radius: 4px;
  border: 1px solid #cccccc;
  padding: 0.3rem 1rem;
  color: #383838;
  display: inline-block;
}

.topnavInfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0.2rem 0;
}

@media all and (max-width: 768px) {
  .topnavInfo {
    display: none;
  }
}

.topnavInfo-left {
  font-size: 13px;
  color: #383838;
}

.topnavInfo-left i {
  margin-right: 0.5rem;
}

.topnavInfo-right {
  font-size: 13px;
  color: #383838;
}

.navMainWrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9;
  background: white;
}

@media all and (max-width: 768px) {
  .navMainWrapper {
    margin-bottom: 2rem;
  }
}

.navWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0;
}

@media all and (max-width: 768px) {
  .navWrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
  }
}

.navWrapper-brand img {
  width: 12rem;
  margin-top: 1rem;
}

@media all and (max-width: 1200px) {
  .navWrapper-brand img {
    margin-right: 0rem;
  }
}

@media all and (max-width: 992px) {
  .navWrapper-brand img {
    width: 7rem;
  }
}

.navWrapper-search input {
  width: 30rem;
  height: 39px;
  padding: 1rem;
  color: #383838;
  border: 1px solid #e6e6e6;
  border-color: #0890b9;
  border-radius: 6px;
}

@media all and (max-width: 1200px) {
  .navWrapper-search input {
    width: 20rem;
  }
}

@media all and (max-width: 992px) {
  .navWrapper-search input {
    width: 14rem;
  }
}

@media all and (max-width: 480px) {
  .navWrapper-search input {
    width: 13rem;
    margin-bottom: 0.5rem;
  }
}

@media all and (max-width: 576px) {
  .navWrapper-search .mobsearch {
    display: none;
  }
}

@media all and (max-width: 768px) {
  .navWrapper-carts {
    display: none;
  }
}

.navWrapper-carts-login {
  padding: 1rem 0.5rem;
  margin-right: 1rem;
  cursor: pointer;
  color: #383838;
}

.navWrapper-carts-login button {
  font-size: 1rem;
  color: #383838;
}

.navWrapper-carts-login button img {
  margin-right: 0.2rem;
  width: 20px;
  margin-bottom: 6px;
}

.navWrapper-carts .myCart {
  position: relative;
  font-size: 1.1rem;
}

.navWrapper-carts .myCart a img,
.navWrapper-carts .myCart a span {
  color: #383838;
  margin-right: 0.2rem;
  width: 20px;
  margin-bottom: 6px;
  font-size: 1rem;
}

.navWrapper-carts .myCart .cartCount {
  background: #f1a6b4;
  min-height: 1.2rem;
  min-width: 1.2rem;
  border-radius: calc(1.3rem);
  font-size: 13px;
  font-weight: 800;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #0890b9;
  position: absolute;
  top: -0.7rem;
  right: 4.2rem;
}

.navCategories {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  margin-top: 1rem;
}

.navCategories-items {
  padding: 1rem;
  color: white;
}

.navbarBox {
  background: #0890b9;
}

@media all and (max-width: 768px) {
  .navbarBox {
    display: none;
  }
}

.navbarBox .menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  list-style-type: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.navbarBox .menu--items {
  position: relative;
  color: white;
  padding: 0.7rem 1rem;
  font-weight: 300;
}

.navbarBox .menu--items .navitems {
  color: white;
}

.navbarBox .menu--items img {
  width: 16px;
}

.navbarBox .menu--items .testm {
  background: green;
  height: 5rem;
}

.navbarBox .menu--items .sub--menu {
  position: absolute;
  z-index: 100;
  background: white;
  border: 1px solid #e6e6e6;
  -webkit-box-shadow: 1px 2px 4px #c4c4c4;
          box-shadow: 1px 2px 4px #c4c4c4;
  left: 0;
  display: none;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  top: 2.9rem;
  border-radius: 4px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  width: 35rem;
}

.navbarBox .menu--items .sub--menu a {
  color: #1d2a35;
}

.navbarBox .menu--items .sub--menu .subcatWrapper {
  padding: 1rem;
}

.navbarBox .menu--items .sub--menu .subcatWrapper--title {
  font-weight: 600;
  color: #d40000;
  font-size: 16px;
}

.navbarBox .menu--items .sub--menu .subcatWrapper--itemholder {
  padding: 0;
}

.navbarBox .menu--items .sub--menu .subcatWrapper--itemholder__items {
  font-size: 14px;
  padding-top: 0.7rem;
  color: #383838;
}

.navbarBox .menu--items .sub--menu .subcatWrapper--itemholder__items:hover {
  color: #0890b9;
}

.navbarBox .menu--items:hover {
  background: #0890b9;
  cursor: pointer;
}

.navbarBox .menu--items:hover .sub--menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.login-modal .close > span {
  color: #f03738;
}

.login-modal .modal-header {
  border: none;
  color: #383838;
}

.login-modal .modal-footer {
  border: none;
}

.carImage {
  height: 32rem;
  -o-object-fit: cover;
     object-fit: cover;
}

@media all and (max-width: 768px) {
  .carImage {
    height: 25rem;
  }
}

@media all and (max-width: 576px) {
  .carImage {
    height: 15rem;
  }
}

.mobileWrapper {
  display: none;
}

@media all and (max-width: 768px) {
  .mobileWrapper {
    display: block;
  }
}

.mobileWrapper .mobileNavbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: fixed;
  z-index: 11;
  -webkit-box-shadow: 1px 1px 12px #24a5dc56;
          box-shadow: 1px 1px 12px #24a5dc56;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid #e6e6e6;
  background: white;
}

.mobileWrapper .mobileNavbar-items {
  text-align: center;
}

.mobileWrapper .mobileNavbar-items i {
  font-size: 1.4rem;
}

.mobileWrapper .mobileNavbar-items-name {
  font-size: 13px;
  letter-spacing: 0.5px;
}

.mobileWrapper .mobileNavbar-items .cartMobCount {
  position: absolute;
  top: 0;
  background: red;
  height: 1rem;
  font-size: 0.7rem;
  width: 1rem;
  border-radius: calc(1rem);
  color: white;
}

.mobActiveTab {
  color: #0890b9 !important;
  display: block;
}

.notActive {
  color: #5f5f5f;
  display: block;
}

.moreContainer {
  background: white;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  padding: 1rem;
  overflow-y: auto;
  z-index: 10;
  -webkit-animation: example 0.3s ease-in-out;
          animation: example 0.3s ease-in-out;
}

.moreContainer ul {
  padding: 0;
}

.moreContainer ul li {
  padding: 1rem;
}

.moreContainer a {
  color: #1d2a35;
}

.moreContainer .accordion-item {
  background-color: #fff;
  border: none !important;
}

.moreContainer .subcatTitle {
  font-weight: 600 !important;
  font-size: 15px !important;
}

@-webkit-keyframes example {
  from {
    left: 100%;
  }
  to {
    left: 0;
  }
}

@keyframes example {
  from {
    left: 100%;
  }
  to {
    left: 0;
  }
}

.movfooter {
  height: 5rem;
  background: white;
  display: none;
}

@media all and (max-width: 768px) {
  .movfooter {
    display: block;
  }
}

section[role='Footer'] {
  margin-top: 8rem;
  background: #0890b9;
}

@media all and (max-width: 768px) {
  section[role='Footer'] {
    display: none;
  }
}

section[role='Footer'] .foooterInfo {
  margin-top: 3rem;
}

section[role='Footer'] .foooterInfo img {
  width: 12rem;
}

section[role='Footer'] .foooterInfo p {
  color: white;
  line-height: 180%;
  width: 100%;
  margin-top: 2rem;
}

section[role='Footer'] .Links {
  color: white;
}

section[role='Footer'] .Links-title {
  color: white;
  margin-top: 3rem;
}

@media all and (max-width: 576px) {
  section[role='Footer'] .Links-title {
    margin-top: 2rem;
  }
}

section[role='Footer'] .Links-box {
  margin-top: 2rem;
  padding: 0;
}

section[role='Footer'] .Links-box-item {
  margin-top: 1rem;
  text-decoration: underline;
  cursor: pointer;
}

section[role='Footer'] .contact {
  color: white;
}

section[role='Footer'] .contact-title {
  margin-top: 3rem;
}

@media all and (max-width: 576px) {
  section[role='Footer'] .contact-title {
    margin-top: 2rem;
  }
}

section[role='Footer'] .contact-box {
  padding: 0;
}

section[role='Footer'] .contact-box-item {
  margin-top: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

section[role='Footer'] .contact-box-item .iconHolder {
  margin-right: 1rem;
}

section[role='Footer'] .contact-box-item .addHolder-value {
  font-size: 14px;
  font-weight: 100;
  color: white !important;
}

section[role='Footer'] .socialMedia {
  color: white;
}

section[role='Footer'] .socialMedia-title {
  margin-top: 3rem;
}

@media all and (max-width: 576px) {
  section[role='Footer'] .socialMedia-title {
    margin-top: 2rem;
  }
}

section[role='Footer'] .socialMedia .socialWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 100%;
  margin-top: 2rem;
}

section[role='Footer'] .socialMedia .socialWrapper-icons {
  cursor: pointer;
  margin-right: 1rem;
  border: 1px solid white;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: calc(2.5rem);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all ease-in 0.2s;
  transition: all ease-in 0.2s;
}

section[role='Footer'] .socialMedia .socialWrapper-icons i {
  color: #f4f4f4;
}

section[role='Footer'] .socialMedia .socialWrapper-icons:hover {
  background-color: white;
  color: #354c74;
}

.copyright {
  padding-bottom: 3rem;
  color: white;
  text-align: center;
}

.loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.loader__item {
  width: 16px;
  height: 16px;
  margin: 4px;
  background: #b3b3b3;
  border-radius: 50%;
  list-style: none;
  -webkit-animation: animation-loading 1.2s infinite;
          animation: animation-loading 1.2s infinite;
}

.loader__item:nth-child(1) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}

.loader__item:nth-child(2) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}

@-webkit-keyframes animation-loading {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  80% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@keyframes animation-loading {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  80% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

.skeleton-box {
  position: relative;
  overflow: hidden;
  background-color: #e2e5e7;
}

.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), color-stop(rgba(255, 255, 255, 0.5)), to(rgba(255, 255, 255, 0)));
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
  background-size: 100px 100%;
  background-repeat: no-repeat;
  -webkit-animation: shimmer 1s ease infinite;
          animation: shimmer 1s ease infinite;
  content: '';
}

@-webkit-keyframes shimmer {
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@keyframes shimmer {
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.layoutTitle {
  margin-top: 5rem;
  margin-bottom: 3rem;
}

.productCard {
  position: relative;
  margin-bottom: 1rem;
  border: 1px solid #e6e6e6;
  padding: 1rem;
}

.productCard-image {
  height: 15rem;
  padding: 1.5rem;
  background: #f3f3f3;
}

.productCard-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.productCard-title {
  color: #1d2a35;
  font-weight: 400 !important;
  height: 2.9rem;
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  /* autoprefixer: off */
  -webkit-line-clamp: 2;
}

.productCard-price {
  margin-top: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.productCard-price .priceValue {
  font-weight: 600;
  font-size: 20px;
  color: #1d2a35;
}

.productCard-price .stockValue {
  color: #3cc13b;
  font-size: 14px;
}

.productCard .cardOff {
  background: #0890b9;
  position: absolute;
  top: 5%;
  left: 5%;
  padding: 0.1rem;
  font-size: 12px;
  color: white;
}

.adsWrapper {
  margin: 5rem 0;
}

.adsWrapper-image img {
  width: 100%;
}

.homeCleanWrapper {
  margin-top: 5rem;
}

.homeCleanWrapper-title {
  margin-bottom: 2rem;
}

.homeCleanWrapper-box {
  width: 80%;
  text-align: justify;
}

@media all and (max-width: 576px) {
  .homeCleanWrapper-box {
    width: 100%;
  }
}

.homeCleanWrapper-image img {
  width: 100%;
}

@media all and (max-width: 768px) {
  .myOrderTable {
    display: none;
  }
}

.loginError {
  background: #fcd7d7;
  margin-top: 1rem;
  padding: 5px 1rem;
  border-radius: 6px;
  color: #f03738;
  width: 100%;
  text-align: center;
}

.productWrapper {
  background: #ffffff;
  -webkit-box-shadow: 1px 2px 14px 6px #8f4c3b0e;
          box-shadow: 1px 2px 14px 6px #8f4c3b0e;
  border-radius: 6px;
  padding: 1rem;
}

.productWrapper .imageHolder .image-gallery-slides {
  height: 25rem;
}

.productWrapper .imageHolder .image-gallery-image {
  border: 1px solid #e6e6e6;
  width: 100%;
  border-radius: 6px;
  padding: 1rem;
  margin-bottom: 1rem;
  height: 25rem;
  padding: 1rem;
  -o-object-fit: contain;
     object-fit: contain;
  background: white;
}

.productWrapper .imageHolder .image-gallery-thumbnail {
  border: 2px solid #d8d8d8;
}

.productWrapper .imageHolder .image-gallery-thumbnail:hover {
  border: 2px solid #0890b9;
}

.productWrapper .imageHolder .active {
  border: 2px solid #0890b9;
}

.productWrapper .imageHolder .image-gallery-thumbnail-image {
  height: 5rem;
  width: 5rem;
  -o-object-fit: contain;
     object-fit: contain;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

@media all and (max-width: 768px) {
  .productWrapper .imageHolder .image-gallery-thumbnail-image {
    width: 4rem;
  }
}

.productWrapper .DetailsHolder-header {
  border-bottom: 1px solid #e6e6e6;
}

.productWrapper .DetailsHolder-header-title {
  color: #1d2a35;
  margin-top: 1rem;
}

.productWrapper .DetailsHolder-header-brand {
  font-size: 1rem;
}

.productWrapper .DetailsHolder-header-brand .brandKey {
  color: #383838;
}

.productWrapper .DetailsHolder-header-brand .brandValue {
  color: #1d2a35;
}

.productWrapper .DetailsHolder-stock-price {
  color: #1d2a35;
  font-size: 1.5rem;
  font-weight: 700;
  margin-right: 0.5rem;
}

.productWrapper .DetailsHolder-stock-cutprice {
  color: #848484;
  font-size: 18px;
  font-weight: 400;
  text-decoration: line-through;
  margin-right: 0.5rem;
}

.productWrapper .DetailsHolder-stock-discount {
  background: #2a9d8f;
  color: white;
  font-size: 12px;
  font-weight: 700;
  padding: 0.3rem 0.7rem;
  margin-bottom: 1rem;
  border-radius: 4px;
}

.productWrapper .DetailsHolder-stock-vatInfo {
  font-size: 12px;
  color: #b3b3b3;
  font-weight: 500;
}

.productWrapper .DetailsHolder-stock-stockInfo {
  text-transform: uppercase;
  font-size: 16px;
  color: #3cc13b;
  font-weight: 700;
}

.productWrapper .DetailsHolder-highlights {
  margin-top: 1rem;
}

.productWrapper .DetailsHolder-highlights ul {
  text-decoration: none;
  list-style-type: circle;
  padding-left: 0;
}

.productWrapper .DetailsHolder-highlights ul li {
  color: #1d2a35;
  font-weight: 500;
  font-size: 16px;
}

.productWrapper .DetailsHolder-qty-name {
  font-weight: 700 !important;
  color: #1d2a35;
}

.productWrapper .DetailsHolder-qty-select {
  margin-top: 1rem;
  width: 5rem;
}

@media all and (max-width: 768px) {
  .productWrapper .DetailsHolder-qty .webQuantity {
    display: none;
  }
}

.productWrapper .DetailsHolder-qty .mobQuantity {
  display: none;
}

@media all and (max-width: 768px) {
  .productWrapper .DetailsHolder-qty .mobQuantity {
    display: block;
  }
}

.productInfoWrapper {
  background: #ffffff;
  -webkit-box-shadow: 1px 2px 14px 6px #8f4c3b0e;
          box-shadow: 1px 2px 14px 6px #8f4c3b0e;
  border-radius: 6px;
  padding: 1rem;
}

.productInfoWrapper .productDesc p {
  line-height: 170%;
}

.productTechInfo {
  background: #ffffff;
  -webkit-box-shadow: 1px 2px 14px 6px #8f4c3b0e;
          box-shadow: 1px 2px 14px 6px #8f4c3b0e;
  border-radius: 6px;
  padding: 1rem;
  height: 100%;
}

.productTechInfo .productTechDesc {
  max-height: 60vh;
  overflow-y: auto;
}

.productTechInfo .productTechDesc ul {
  padding: 0;
}

.productTechInfo .productTechDesc ul .productTechDesc-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  color: #1d2a35;
}

@media all and (max-width: 576px) {
  .productTechInfo .productTechDesc ul .productTechDesc-box {
    font-size: 12px;
  }
}

.productTechInfo .productTechDesc ul .productTechDesc-box-key {
  width: 30%;
  font-weight: 700;
}

@media all and (max-width: 576px) {
  .productTechInfo .productTechDesc ul .productTechDesc-box-key {
    width: 45%;
  }
}

.relatedHolder {
  background: #ffffff;
  -webkit-box-shadow: 1px 2px 14px 6px #8f4c3b0e;
          box-shadow: 1px 2px 14px 6px #8f4c3b0e;
  border-radius: 6px;
}

.singleImageProduct {
  width: 100%;
  height: 20rem;
  -o-object-fit: contain;
     object-fit: contain;
}

.image-gallery-icon {
  display: none;
}

.image-gallery-slides {
  height: 20rem;
  background: #eeeeee;
  border-radius: 6px;
  overflow: hidden;
}

.image-gallery-thumbnail {
  background: #f8f8f8;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  outline: none;
  border: 4px solid #354c74;
}

.addedFail {
  -webkit-animation: example2 0.3s ease-in-out;
          animation: example2 0.3s ease-in-out;
  width: 20rem;
  padding: 0.3rem 0.7rem;
  border-radius: 6px;
  color: #f03738;
}

.addedSuccess {
  -webkit-animation: example2 0.3s ease-in-out;
          animation: example2 0.3s ease-in-out;
  font-size: 15px;
  background: #d7f3d7;
  width: 0100%;
  padding: 0.3rem 0.7rem;
  border-radius: 6px;
}

@-webkit-keyframes example2 {
  from {
    -webkit-transform: translatey(-10px);
            transform: translatey(-10px);
    opacity: 0;
  }
  to {
    -webkit-transform: translatey(0);
            transform: translatey(0);
    opacity: 1;
  }
}

@keyframes example2 {
  from {
    -webkit-transform: translatey(-10px);
            transform: translatey(-10px);
    opacity: 0;
  }
  to {
    -webkit-transform: translatey(0);
            transform: translatey(0);
    opacity: 1;
  }
}

@media all and (max-width: 576px) {
  .prodbuttons {
    width: 100%;
  }
}

.counterHolder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1rem;
}

.counterHolder-item {
  border: 1px solid #e6e6e6;
  height: 3rem;
  width: 3rem;
  display: -ms-grid;
  display: grid;
  place-items: center;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
}

.allproductInfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

@media all and (max-width: 576px) {
  .allproductInfo {
    margin-bottom: 4rem;
  }
}

.allproductInfo select {
  width: 10rem;
}

@media all and (max-width: 576px) {
  .allproductInfo select {
    width: 5rem;
  }
}

.allproductInfo .itemsnumber {
  font-size: 14px;
}

@media all and (max-width: 576px) {
  .allproductInfo .itemsnumber {
    width: 5rem;
  }
}

.suprtSubFilter-item {
  min-width: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border: 1px solid #e6e6e6;
  padding: 0.5rem;
  border-radius: 6px;
  margin: 1rem 0;
}

.rangeHolder-title {
  margin-top: 2rem;
}

.rangeHolder-input input {
  width: 50%;
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: auto !important;
  max-height: 65vh !important;
  border-radius: 16px 16px 0 0;
}

.offcanvas-backdrop.show {
  opacity: 0.85 !important;
}

.offcanvas-header {
  padding: 1rem 1rem 0 1rem !important;
}

@media all and (max-width: 576px) {
  .filterHolder {
    display: none;
  }
}

.mobileFilter {
  display: none;
}

@media all and (max-width: 576px) {
  .mobileFilter {
    display: block;
  }
}

.checkoutWrapper {
  margin-top: 2rem;
}

.checkoutWrapper .cartItem {
  -webkit-box-shadow: 1px 2px 14px 6px #8f4c3b0e;
          box-shadow: 1px 2px 14px 6px #8f4c3b0e;
  border-radius: 6px;
  padding: 1rem;
}

.checkoutWrapper .cartItem-title {
  font-size: 14px;
  font-weight: 400;
  color: #1d2a35;
  border-bottom: 1px solid #e6e6e6;
}

@media all and (max-width: 576px) {
  .checkoutWrapper .cartItem-title {
    display: none;
  }
}

.checkoutWrapper .cartItem-items {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 0.5rem;
}

.checkoutWrapper .cartItem-items .itemInfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.checkoutWrapper .cartItem-items .itemInfo-image {
  height: 4rem;
  width: 4rem;
  background: #f8f8f8;
  border-radius: 4px;
  display: -ms-grid;
  display: grid;
  place-items: center;
  margin-bottom: 0;
}

.checkoutWrapper .cartItem-items .itemInfo-image img {
  height: 3.5rem;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.checkoutWrapper .cartItem-items .itemInfo-info {
  padding-left: 1rem;
}

.checkoutWrapper .cartItem-items .itemInfo-info-title {
  font-weight: bold;
  color: #1d2a35;
  font-size: 16px;
  margin-bottom: 0;
}

.checkoutWrapper .cartItem-items .itemInfo-info-brand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  color: #1d2a35;
}

.checkoutWrapper .cartItem-items .itemInfo-info-brand-key {
  width: 14%;
  font-weight: 700;
}

.checkoutWrapper .cartItem-items .itemInfo-info-quantity {
  width: 5rem;
  margin-top: 1rem;
}

.checkoutWrapper .cartItem-items .itemInfo-info-remove {
  font-size: 14px;
  font-weight: 600;
  color: #2a9d8f;
  cursor: pointer;
}

.checkoutWrapper .cartItem-items-unit {
  margin-top: 20%;
  font-size: 16px;
  color: #1d2a35;
}

@media all and (max-width: 576px) {
  .checkoutWrapper .cartItem-items-unit {
    display: none;
  }
}

.checkoutWrapper .cartItem-items-sub {
  margin-top: 20%;
  font-size: 16px;
  font-weight: 700;
  color: #1d2a35;
}

.checkoutWrapper .continue {
  color: #0890b9;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}

.checkoutWrapper .continue:hover {
  color: #0890b9;
}

.checkoutWrapper .continue i {
  font-size: 0.65rem;
}

.reqError {
  outline: 1px solid #f03738;
}

.billDetails {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #ffffff;
  -webkit-box-shadow: 1px 2px 14px 6px #8f4c3b0e;
          box-shadow: 1px 2px 14px 6px #8f4c3b0e;
  border-radius: 6px;
  padding: 1rem;
}

.billDetails .calc {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 1rem;
}

.billDetails .calc-holder {
  margin-top: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 16px;
  color: #1d2a35;
}

.billDetails .summary {
  color: #0890b9;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}

.billDetails .summary:hover {
  color: #0890b9;
}

.overlayLoader {
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.767);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  display: -ms-grid;
  display: grid;
  place-items: center;
  overflow: hidden !important;
}

.overlayLoader-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.overlayLoader-item span {
  color: white !important;
  margin-left: 10px;
}

.guestCheckout {
  text-align: center;
  margin: 2rem 0;
}

.shippingWrapper {
  margin-top: 2rem;
}

.shippingWrapper .shippingForm {
  -webkit-box-shadow: 1px 2px 14px 6px #8f4c3b0e;
          box-shadow: 1px 2px 14px 6px #8f4c3b0e;
  border-radius: 6px;
  padding: 1rem;
}

.shippingWrapper .paymentMethod {
  margin-top: 1rem;
}

.shippingWrapper .paymentMethod .paymentList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.shippingWrapper .paymentMethod .paymentList-item {
  border: 1px solid #e6e6e6;
  padding: 2rem;
  margin: 0.5rem;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.3rem;
}

.accountWrapper {
  margin-top: 2rem;
}

.accountWrapper .accountTabHolder-items {
  padding: 0.5rem;
  color: #1d2a35;
  cursor: pointer;
}

.accountWrapper .accountTabHolder-items:hover {
  color: #0890b9;
}

.orderWrapper {
  -webkit-box-shadow: 1px 2px 14px 6px #8f4c3b0e;
          box-shadow: 1px 2px 14px 6px #8f4c3b0e;
  padding: 0.5rem;
  margin-top: 2rem;
}

.orderWrapper thead {
  background: white;
  color: #1d2a35;
}

.orderWrapper th {
  font-weight: 600 !important;
  border-width: 0px !important;
}

.orderWrapper .imageHolder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.orderWrapper .imageHolder figure {
  margin: 0;
}

.orderWrapper .imageHolder figure img {
  height: 2.5rem;
  width: 2.5rem;
  -o-object-fit: contain;
     object-fit: contain;
  margin-right: 1rem;
  border: 1px solid #e6e6e6;
}

.orderWrapper .imageHolder-text {
  font-size: 1.1rem;
}

@media all and (max-width: 576px) {
  .orderWrapper .imageHolder-text {
    font-size: 14px;
  }
}

.detailsWrapper {
  width: 50%;
}

@media all and (max-width: 768px) {
  .detailsWrapper {
    width: 100%;
  }
}

@media all and (max-width: 576px) {
  .guestRegister {
    display: none;
  }
}

.cleanHero {
  background: #f1a6b4;
}

.cleanHero-image {
  margin-top: 1rem;
}

.cleanHero-image img {
  width: 100%;
}

.cleanHero-desc {
  margin-top: 5rem;
}

.cleanHero-desc-title {
  font-size: 58px !important;
}

@media all and (max-width: 576px) {
  .cleanHero-desc-title {
    font-size: 28px !important;
  }
}

.cleanHero-desc-para {
  margin-top: 2rem;
  line-height: 180%;
}

.serviceHolder {
  margin-top: 5rem;
}

.cleaningCard {
  margin-top: 2rem;
  height: 15rem;
  border: 1px solid #e6e6e6;
  padding: 0.5rem;
}

.cleaningCard-image {
  margin: 0;
}

.cleaningCard-image img {
  width: 100%;
  height: 10rem;
  -o-object-fit: cover;
     object-fit: cover;
}

.cleaningCard-image figcaption {
  text-align: center;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 600 !important;
}

.galleryHolder {
  margin-top: 5rem;
}

.galleryHolder h2 {
  margin-bottom: 2rem;
}

.galleryHolder-image img {
  width: 100%;
  height: 20rem;
  -o-object-fit: cover;
     object-fit: cover;
}
