.shippingWrapper {
  margin-top: 2rem;
  .shippingForm {
    box-shadow: $shadow;
    border-radius: $radius;
    padding: 1rem;
  }

  .paymentMethod {
    margin-top: 1rem;

    .paymentList {
      display: flex;

      &-item {
        border: $border;
        padding: 2rem;
        margin: 0.5rem;
        border-radius: $radius;
        cursor: pointer;
        font-weight: 600;
        font-size: 1.3rem;
      }
    }
  }
}
