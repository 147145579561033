.cleanHero {
  background: color(primary, tint2);
  &-image {
    margin-top: 1rem;
    img {
      width: 100%;
    }
  }
  &-desc {
    margin-top: 5rem;
    &-title {
      font-size: 58px !important;
      @include responsive-for(sm) {
        font-size: 28px !important;
      }
    }
    &-para {
      margin-top: 2rem;
      line-height: 180%;
    }
  }
}

.serviceHolder {
  margin-top: 5rem;
}

.cleaningCard {
  margin-top: 2rem;
  height: 15rem;
  border: $border;
  padding: 0.5rem;

  &-image {
    margin: 0;
    img {
      width: 100%;
      height: 10rem;
      object-fit: cover;
    }

    figcaption {
      text-align: center;
      margin-top: 1rem;
      font-size: 1rem;
      font-weight: 600 !important;
    }
  }
}

.galleryHolder {
  margin-top: 5rem;
  h2 {
    margin-bottom: 2rem;
  }

  &-image {
    img {
      width: 100%;
      height: 20rem;
      object-fit: cover;
    }
  }
}
